import React from "react";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <section className="w-full px-4">
      <section className="w-full px-4">
        <p className="text-white text-3xl font-bold mb-2 w-1/2 text-center mx-auto mt-10 mb-10 ">
          Terms and Conditions for Trust Vault
        </p>

        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Effective Date: 08-11-2024 <br></br>
          Trust Vault LLC <br></br>
          Incorporated in Wyoming, United States <br></br>
          Welcome to Trust Vault, operated by Trust Vault LLC ("we," "us,"
          "our"). By accessing or using the Trust Vault application ("App"), you
          agree to comply with these Terms and Conditions. Please read them
          carefully.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          1. Acceptance of Terms
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          By using Trust Vault, you agree to be bound by these Terms and
          Conditions and any additional terms that may apply. If you do not
          agree, please discontinue use of the App.
        </p>
        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          2. Description of Service
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault is a self-custody crypto wallet that allows you to manage
          your crypto assets and make purchases through third-party
          decentralized exchanges ("DEXs") integrated within the App. The
          services provided by Trust Vault LLC are subject to the terms outlined
          here.
        </p>
        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          3. Eligibility{" "}
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          To use Trust Vault, you must be at least 18 years old and have the
          legal capacity to enter into these Terms. By agreeing, you represent
          that you meet these requirements.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          4. Self-Custody of Assets
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault enables self-custody of crypto assets, meaning you have
          full control and responsibility over your private keys and funds. We
          do not hold or have access to your assets. You are solely responsible
          for safeguarding your account credentials, private keys, and recovery
          phrases. We do not have any ability to retrieve lost or stolen keys,
          so please take measures to secure your account.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          5. Prohibited Activities
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          When using Trust Vault, you agree not to: <br></br>- Use the App for
          illegal, harmful, or fraudulent activities. <br></br>- Distribute,
          transmit, or store harmful code, malware, or viruses through the App.{" "}
          <br></br>- Attempt to access unauthorized accounts or interfere with
          the App's security. <br></br>- Violate any applicable laws or
          regulations.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          6. Fees and Payments{" "}
        </p>

        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          While Trust Vault does not directly charge fees, any fees incurred
          from third-party DEX transactions or gas fees are your responsibility.
          These fees are subject to the terms of the respective third-party
          providers.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          7. Use of Third-Party Services
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault integrates with third-party DEXs to facilitate crypto
          transactions. By using these services, you are subject to their terms
          and conditions, privacy policies, and any fees they may charge. Trust
          Vault LLC is not liable for any issues arising from your use of
          third-party services.
        </p>
        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          8. Intellectual Property Rights
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          All content, trademarks, logos, and intellectual property within Trust
          Vault are the property of Trust Vault LLC or our licensors. You are
          granted a limited, non-exclusive, and non-transferable license to
          access and use the App for personal, non-commercial purposes. Any
          unauthorized use of the App or its content is prohibited.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          9. Limitation of Liability
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault LLC and its affiliates, officers, directors, employees,
          and agents shall not be liable for any indirect, incidental, special,
          or consequential damages arising out of or in connection with your use
          of the App. This limitation of liability applies to any claims related
          to errors, delays, interruptions, or loss of access to your assets.
        </p>
        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          10. Indemnification
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          You agree to indemnify and hold harmless Trust Vault LLC, its
          affiliates, officers, directors, employees, and agents from any
          claims, damages, or expenses arising from your use of the App or
          violation of these Terms.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          11. Changes to Terms
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          We may modify these Terms and Conditions periodically. When changes
          are made, we will notify you within the App or through other
          communications. By continuing to use the App after changes are
          implemented, you agree to be bound by the updated Terms. To view the
          latest version, please visit our website at https://trustvault.app and
          click on the Terms and Conditions tab.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          12. Governing Law
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          These Terms and Conditions are governed by the laws of the State of
          Wyoming, United States, without regard to its conflict of law
          principles. You agree to submit to the exclusive jurisdiction of the
          courts located in Wyoming.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          13. Termination
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault LLC reserves the right to suspend or terminate your access
          to the App if you violate these Terms, engage in prohibited
          activities, or if we determine it necessary for security reasons.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          14. Contact Us
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          For questions or concerns regarding these Terms and Conditions, please
          contact us at: <br></br>
          Email: support@trustvault.app
        </p>
      </section>
      <Footer />
    </section>
  );
};

export default TermsAndConditions;
