import React from "react";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <section className="w-full px-4">
      <section className="w-full px-4">
        <p className="text-white text-3xl font-bold mb-2 w-1/2 text-center mx-auto mt-10 mb-10 ">
          Privacy Policy for Trust Vault
        </p>

        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Effective Date: 08-11-2024 <br></br>
          Trust Vault LLC <br></br>
          Incorporated in Wyoming, United States <br></br>
          Trust Vault LLC ("we," "us," or "our") operates the Trust Vault
          application ("App"), providing you with a self-custody crypto wallet
          to securely manage your crypto assets and purchase assets via
          third-party decentralized exchanges ("DEXs") within the App. This
          Privacy Policy explains how we collect, use, disclose, and protect
          your information. By using Trust Vault, you agree to the practices
          outlined in this policy.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          1. Information We Collect
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          We prioritize your privacy and are committed to collecting only the
          minimal personal information needed. However, some data is necessary
          to provide and enhance our services. The types of data we may collect
          include: <br></br>
          A. Information You Provide <br></br>
          Account Setup: To enhance security, we may collect limited information
          like your email address or other optional account credentials. Note
          that Trust Vault is designed as a self-custody wallet, so your private
          keys and transaction data are fully owned and managed by you, not by
          us. <br></br>
          B. Automatically Collected Information<br></br>
          Device Information: We may collect data such as device type, operating
          system, and app version to improve compatibility and performance.
          <br></br>
          Usage Data: Information on how you interact with the App, including
          features accessed and duration of use, to understand engagement and
          preferences.<br></br>
          Log Data: When errors or crashes occur, we collect diagnostic and log
          information to analyze and fix technical issues.<br></br>
          C. Third-Party Information<br></br>
          When you use third-party services (e.g., decentralized exchanges)
          within the App, those services may collect data independently of Trust
          Vault. We do not control or assume responsibility for third-party
          privacy practices.
        </p>
        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          2. How We Use Your Information{" "}
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault LLC uses collected data for the following purposes:{" "}
          <br></br>- To Provide and Improve the App: Facilitate self-custody and
          secure access to your crypto assets, improve app features, and enhance
          user experience.<br></br>- Customer Support: Respond to inquiries,
          troubleshoot issues, and support your needs.<br></br>- Security:
          Monitor and detect fraud or other potential unauthorized activities
          within the App.<br></br>- Legal Compliance: Comply with applicable
          laws, regulations, or legal requests.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          3. Data Security
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          We take your security seriously and employ administrative, technical,
          and physical safeguards to protect your data. However, no transmission
          over the internet or storage method is entirely secure. You are
          responsible for maintaining the security of your private keys and
          account credentials.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          4. Information Sharing and Disclosure
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          We do not sell or rent your personal information to third parties. We
          may share data in limited scenarios, including: <br></br>- With
          Third-Party Service Providers: Trusted partners who assist us in
          operating the App and who adhere to privacy and security standards.{" "}
          <br></br>- Legal Requirements: When required by law, regulation, or
          legal process, we may disclose necessary information. <br></br>-
          Business Transfers: If we undergo a merger, acquisition, or asset
          sale, your information may be transferred, subject to the acquiring
          entity’s privacy policies.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          5. Third-Party Decentralized Exchanges
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          When purchasing crypto assets via integrated DEXs, you are subject to
          the privacy policies of those platforms. We do not manage, monitor, or
          control their data collection practices and encourage you to review
          their policies for further understanding.
        </p>

        <p className="text-white text-xl font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          6. Children’s Privacy
        </p>

        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Trust Vault is not intended for users under the age of 18. We do not
          knowingly collect personal information from children under 18. If you
          believe we have unintentionally collected such data, please contact
          us, and we will delete it promptly.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          7. Data Retention
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          We retain information for as long as necessary to provide our services
          and fulfill legal obligations. Once data is no longer needed, it is
          securely deleted or anonymized.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          8. Your Rights and Choices
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Depending on your jurisdiction, you may have rights related to your
          personal information, including the rights to access, correct, or
          delete your data. Please contact us at support@trustvault.app to
          exercise your rights. Note that some data may be exempt from such
          requests under specific legal conditions.
        </p>

        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          9. Changes to This Privacy Policy
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          We may update this Privacy Policy periodically. To view the latest
          version, please visit our website https://trustvault.app and click on
          the Privacy Policy tab.
        </p>
        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          10. Contact Us
        </p>
        <p className="text-white text-lg mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          For questions, concerns, or requests regarding this Privacy Policy or
          Trust Vault’s data practices, please contact us at:
        </p>
        <p className="text-white text-lg font-bold mb-2 w-1/2 text-left mx-auto mt-10 mb-10 ">
          Email: support@trustvault.app
        </p>
      </section>
      <Footer />
    </section>
  );
};

export default PrivacyPolicy;
