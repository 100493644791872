import React, { useState } from "react";
import exit from "./images/exit.svg";
import plus from "./images/plus.svg";

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);
  const faqData = [
    {
      id: 1,
      question: "What is a multisig crypto wallet?",
      answer:
        "A multisig (multi-signature) crypto wallet is a digital wallet that requires multiple private keys to authorize a transaction. This enhances security by ensuring that more than one person or device must approve a transaction before it is executed. For instance, in a 2-of-3 multisig wallet, two out of three designated private keys must approve a transaction.",
    },
    {
      id: 2,
      question: "How does a multisig wallet enhance security?",
      answer:
        "A multisig wallet enhances security by requiring multiple approvals before a transaction can proceed. This reduces the risk of theft, as one compromised key is not enough to authorize a transaction.",
    },
    {
      id: 3,
      question:
        "What does it mean that the wallet stores secret keys on the device’s keychain?",
      answer:
        "Storing secret keys on the device’s keychain provides an additional layer of security by keeping keys secure and accessible only to the wallet application.",
    },
    {
      id: 4,
      question: "What are the different configurations of a multisig wallet?",
      answer:
        "Multisig wallets can be configured in various ways, such as 2-of-3, 3-of-5, etc., meaning different numbers of keys are needed for transaction approval.",
    },
    {
      id: 5,
      question:
        "How does the keychain storage enhance security in a multisig wallet?",
      answer:
        "Keychain storage enhances security by securing keys on the device itself, making them harder to access unauthorizedly.",
    },
    {
      id: 6,
      question:
        "What happens if one of the keys in a multisig wallet is lost, especially with keychain storage?",
      answer:
        "If a key is lost, the wallet’s multisig configuration may allow transactions with the remaining keys, depending on its setup.",
    },
    {
      id: 7,
      question: "Are multisig wallets compatible with all cryptocurrencies?",
      answer:
        "Not all cryptocurrencies support multisig wallets; compatibility depends on the blockchain technology and wallet provider.",
    },
    {
      id: 8,
      question:
        "How do transaction approvals work in a multisig wallet with keychain-stored keys?",
      answer:
        "In a multisig wallet with keychain-stored keys, each transaction requires approval from a set number of keys, which are securely stored in the device's keychain.",
    },
  ];

  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <section
      className="w-full mt-16 bg-full-screen-shadow bg-no-repeat"
      style={{ backgroundPosition: "-60% 0%", backgroundSize: "65%" }}
    >
      <div className="w-full px-4 text-gray-800">
        <div className="max-w-6xl mx-auto gap-8 text-white">
          <h1 className="text-5xl font-extrabold text-white mb-8">FAQ</h1>

          {faqData.map((item) => (
            <div key={item.id} className="mb-4">
              <div
                className="flex justify-between items-center py-6 rounded-lg cursor-pointer"
                onClick={() => toggleExpand(item.id)}
              >
                <div className="flex space-x-4 items-center">
                  <span
                    className={`text-xl font-semibold ${
                      expanded === item.id ? "text-blue-500" : "text-white"
                    }`}
                  >
                    {item.id.toString().padStart(2, "0")}
                  </span>
                  <span
                    className={`text-xl font-semibold ${
                      expanded === item.id ? "text-blue-500" : "text-white"
                    }`}
                  >
                    {" "}
                    {item.question}
                  </span>
                </div>
                <div className="flex items-center">
                  <img src={expanded === item.id ? exit : plus} alt="Toggle" />
                </div>
              </div>

              {/* Answer (conditionally rendered) */}
              {expanded === item.id && (
                <div className="py-6 rounded-lg">
                  <p className="text-stone-300 text-lg sm:text-base">
                    {item.answer}
                  </p>
                </div>
              )}

              <hr />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
