import { useState } from "react";
import { Link } from "react-router-dom";
import trustLogo from "./images/trustvault.svg";
import edge from "./images/edge.png";
import mac from "./images/mac.png";
import chrome from "./images/chrome.png";
import brave from "./images/brave.png";
import ios from "./images/ios.png";
import android from "./images/android.png";
import mainDownload from "./images/download-app-bg.png";
import nextLevelShadow from "./images/next-level-shadow.svg";
import letsTalk from "./images/letsTalk-shadow.svg";
import footerTel from "./images/footerTel.svg";
import footerTwitter from "./images/footerTwitter.svg";
import footerLinkedIn from "./images/footerLinkedIn.svg";
import footerMedium from "./images/footerMedium.svg";
import footerFacebook from "./images/footerFacebook.svg";
import footerGithub from "./images/footerGithub.svg";
import downloadAppShadow from "./images/download-app-shadow.svg";

export default function Footer() {
  return (
    <section
      className="bg-next-level bg-cover bg-no-repeat py-14"
      style={{ backgroundPosition: "-50% 5%", backgroundSize: "75%" }}
    >
      <style jsx>{`
        @media (max-width: 768px) {
          .bg-next-level {
            background-image: none; /* Remove background image on smaller screens */
          }
          footer {
            flex-direction: column; /* Stack footer columns on smaller screens */
          }
          .footer-right-section {
            display: grid;
            grid-template-columns: repeat(2, 1fr); /* Two columns per row */
            gap: 16px; /* Adjust spacing between items */
          }
          .footer-info-text {
            width: 100%; /* Make text full width */
          }
        }
        @media (max-width: 640px) {
          .footer-info-text {
            width: 100% !important; /* Ensure full width on very small screens */
          }
          .footer-right-section {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      `}</style>

      <div className="w-full px-4">
        <div className="max-w-6xl mx-auto mb-12">
          {/* First Section */}
          <div className="flex flex-col md:flex-row items-start justify-between">
            {/* Left Side: Heading and Paragraph */}
            <div className="md:w-1/3 mb-8 md:mb-0">
              <h2 className="text-4xl font-extrabold mb-4 white-text-shadow text-left">
                Platform Support
              </h2>
              <p className="text-stone-300 text-left">
                Our wallet is available on iOS, Android, and Desktop, and
                supports all major coins and tokens.
              </p>
            </div>

            {/* Right Side: Small Cards */}
            <div className="md:w-2/3 flex flex-wrap justify-center md:justify-end gap-4">
              {/* Cards */}
              <img
                src={ios}
                alt="iOS"
                className="w-[24%] md:w-[10%] max-w-[80px] mb-4"
              />
              <img
                src={android}
                alt="Android"
                className="w-[24%] md:w-[10%] max-w-[80px] mb-4"
              />
              <img
                src={mac}
                alt="Mac"
                className="w-[24%] md:w-[10%] max-w-[80px] mb-4"
              />
              <img
                src={chrome}
                alt="Chrome"
                className="w-[24%] md:w-[10%] max-w-[80px] mb-4"
              />
              <img
                src={brave}
                alt="Brave"
                className="w-[24%] md:w-[10%] max-w-[80px] mb-4"
              />
              <img
                src={edge}
                alt="Edge"
                className="w-[24%] md:w-[10%] max-w-[80px] mb-4"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mt-4 px-4 text-center">
        <footer className="flex flex-col md:flex-row justify-between items-start text-left text-white py-6 rounded-lg">
          {/* Left Side: Logo and Text */}
          <div className="md:w-1/2 mb-4 md:mb-0 text-stone-300">
            <img src={trustLogo} alt="Trust Logo" />
            <p className="mb-3 mt-3 max-w-md">
              Copyright © 2024 Trust Vault Holdings. All rights reserved.
            </p>
            <p className="mb-3 mt-3">Email: info@trustvault.app</p>
            <p className="max-w-lg mb-3 mt-3 footer-info-text text-sm">
              The information provided on this website is for informational
              purposes only and should not be considered as financial or
              investment advice. Cryptocurrency transactions carry inherent
              risks, including the potential loss of funds. While we employ
              advanced security measures, such as our SafeSend feature, to
              protect your assets, we cannot guarantee the absolute security of
              your funds. Always ensure that you are using the correct wallet
              addresses and follow best practices for securing your private
              keys. By using our services, you agree to our terms and conditions
              and acknowledge that you are responsible for any transactions made
              through our platform.
            </p>
          </div>

          {/* Right Side: Footer Links */}
          <div className="md:w-1/2 flex flex-col md:flex-row justify-between gap-x-2 footer-right-section">
            <ul className="flex flex-col justify-between mb-4 md:mb-0 space-y-5 text-stone-300">
              <li className="mb-2 font-bold text-white">TRUSTVAULT</li>
              <li className="mb-2">Home</li>
              <li className="mb-2">About us</li>
              <li className="mb-2">Features</li>
              <li className="mb-2">Press & Partners</li>
              <li className="mb-2">Blog</li>
              <Link to="/privacypolicy">
                <li className="mb-2">Privacy Policy</li>
              </Link>
              <Link to="/terms">
                <li className="mb-2">Terms and Conditions</li>
              </Link>
            </ul>

            <ul className="flex-1 flex flex-col justify-between mb-4 md:mb-0 space-y-5 text-stone-300 mr-4 lg:ml-[30px]">
              <li className="mb-2 font-bold text-white">DISCOVER</li>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>Learn & Explore</p>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>Getting Started Guide</p>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>Blockchain & Crypto 101</p>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>FAQ</p>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>Knowledge Base</p>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>Stay Connected</p>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">&#8226;</span>
                <p>Tutorials</p>
              </div>
            </ul>

            <ul className="md:w-1/4 flex flex-col justify-between mb-4 md:mb-0 space-y-5 text-stone-300">
              <li className="mb-2 font-bold text-white">FOLLOW US</li>
              <div className="flex items-center gap-3 mb-2">
                <img src={footerTel} alt="Telegram" />
                <li className="mb-2">Telegram</li>
              </div>
              <div className="flex items-center gap-3 mb-2">
                <img src={footerTwitter} alt="X(Twitter)" />
                <li className="mb-2">X(Twitter)</li>
              </div>
              <div className="flex items-center gap-3 mb-2">
                <img src={footerLinkedIn} alt="LinkedIn" />
                <li className="mb-2">LinkedIn</li>
              </div>
              <div className="flex items-center gap-3 mb-2">
                <img src={footerMedium} alt="Medium" />
                <li className="mb-2">Medium</li>
              </div>
              <div className="flex items-center gap-3 mb-2">
                <img src={footerFacebook} alt="Facebook" />
                <li className="mb-2">Facebook</li>
              </div>
              <div className="flex items-center gap-3 mb-2">
                <img src={footerGithub} alt="Github" />
                <li className="mb-2">Github</li>
              </div>
            </ul>
          </div>
        </footer>
      </div>
    </section>
  );
}
